$red: #EE4036;

body {
	nav {}

	.btn,
	.btn-large,
	.btn-small {
		text-decoration: none;
		color: #fff;
		background-color: $red;
		text-align: center;
		letter-spacing: 0.5px;
		-webkit-transition: background-color 0.2s ease-out;
		transition: background-color 0.2s ease-out;
		cursor: pointer;
	}

	.btn-small {
		height: 30px;
		line-height: 30px;
		font-size: 13px;
	}

	.container {
		width: 1120px;
	}
}