* {
	/* font-family: "Fira Sans", Helvetica, Arial, sans-serif; */
	font-family: "Roboto", "Fira Sans", Helvetica, Arial;
}

.dataTables_paginate a.paginate_button.current {
	background-color: #0080005c !important;
}

.modal-cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	transform: translateZ(0);
	background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 2.5em 1.5em 1.5em 1.5em;
	background-color: #ffffff;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
	/* overflow-y: auto; */
	-webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
	/* Center the Modal! */
	.modal-area {
		left: 50%;
		top: 50%;
		height: auto;
		transform: translate(-50%, -50%);
		max-width: 650px;
		max-height: calc(100% - 1em);
	}
}

._modal-close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.5em;
	line-height: 1;
	background: #f6f6f7;
	border: 0;
	box-shadow: 0;
	cursor: pointer;
}

._modal-close-icon {
	width: 25px;
	height: 25px;
	fill: transparent;
	stroke: black;
	stroke-linecap: round;
	stroke-width: 2;
}

.modal-body {
	padding-top: 0.25em;
}

._hide-visual {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.scroll-lock {
	overflow: hidden;
	margin-right: 17px;
}

.collection .collection-item .secondary-content {
	position: absolute;
	top: 16px;
	right: 16px;
}

body .store .btn:hover {
	background-color: #fcb040 !important;
}

body .store .btn {
	background-color: #3a3a3a !important;
}

.link:hover {
	text-decoration: underline;
}

.link {
	cursor: pointer;
}

.container h1 {
	color: #be1e2d;
	border: 5px solid #f6921e;
	border-radius: 20px;
	display: inline-block;
	background-color: #fff;
	font-size: 25px;
	font-weight: 500;
	padding: 5px 20px;
	text-transform: uppercase;
}

/* form style */
.forumEntryBox,
fieldset {
	border: 1px solid #ee4036 !important;
	border-radius: 15px;
	background-color: rgba(246, 147, 30, 0.15);
}

legend {
	color: #ee4036;
	margin-left: 30px;
	padding: 10px;
	font-size: 22px;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
	border-bottom: 1px solid #f6921e;
	-webkit-box-shadow: 0 1px 0 0 #f6921e;
	box-shadow: 0 1px 0 0 #f6921e;
	color: #f6921e;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
input[type="password"]:not(.browser-default):focus:not([readonly]) + label,
input[type="email"]:not(.browser-default):focus:not([readonly]) + label,
input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime"]:not(.browser-default):focus:not([readonly]) + label,
input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
	+ label,
input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
input[type="number"]:not(.browser-default):focus:not([readonly]) + label,
input[type="search"]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: #f6921e;
}

.select-wrapper input.select-dropdown:focus {
	border-bottom: 1px solid #f6921e;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
	color: black;
}

[type="radio"]:checked + span::after,
[type="radio"].with-gap:checked + span::after {
	background-color: #ee4036;
}

[type="radio"]:checked + span::after,
[type="radio"].with-gap:checked + span::before,
[type="radio"].with-gap:checked + span::after {
	border: 2px solid #ee4036;
}

.container {
	margin: 0 auto;
	max-width: 1500px;
	width: 100% !important;
	padding: 0 2.25%;
}

/* Tablet style m-size */
@media only screen and (min-width: 601px) {
	.content800 {
		max-width: 800px;
	}
}

/* Desktop style l-size*/
@media only screen and (min-width: 993px) {
	.container {
		padding: 0 6.25%;
	}
	.content800 {
		max-width: 800px;
	}
}

/* Desktop style xl-size*/
@media only screen and (min-width: 1900px) {
	.container {
		padding: 0 12%;
	}
	.content800 {
		max-width: 800px;
	}
}
